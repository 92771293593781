<div *ngIf="display">
    <mat-toolbar style="height: 120px;"   
          fxHide fxShow.gt-md  class=" app-toolbar bg" fxLayoutAlign="space-between center">
     <mat-toolbar-row  class="theme-container adj"  fxLayoutAlign="space-between center">
 
                 <div fxLayout="row" fxLayoutGap="10px" fxFlex="20" >
   
   
                     <section >
                       
                         <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
   
                     </section>
                  
                 </div>
   
                 <!-- <div fxFlex fxHide fxShow.gt-sm class="list-flex user-tool"> -->
                     <div  fxLayout="row" style="border-radius: 60px;box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);padding: 12px ">
                   
                     <calsoft-Menu fxLayoutAlign="center center" style="z-index: 1000;" [data]="data" [type]="'one'" [categoryArray]="item"></calsoft-Menu>
                     <div *ngIf="data.disableLoginHeader==0">
                     <div *ngIf="!userLoggedIn"(click)="SignIn('login')" fxLayoutAlign="end center">
                         <button class="btncss" *ngIf="!userLoggedIn">Login/Signup
                         </button>
                     </div>
                     </div>
 
                     <calsoft-HeaderUserProfileDropdown [data]="data"  fxLayoutAlign="end center"  [color]="'white'" *ngIf="userLoggedIn" class="dropcss"></calsoft-HeaderUserProfileDropdown>
 
                     <div fxLayoutAlign="end center"  *ngIf="url != '/cart'">
                         <calsoft-cart-icon  [color]="true"></calsoft-cart-icon>
                     </div>
                     
                 </div>
               </mat-toolbar-row>
   
   </mat-toolbar>
   <mat-toolbar fxHide fxShow.gt-md >
 
     </mat-toolbar>
     </div>
 
     <div *ngIf="!display">
  
         <div *ngIf="mobileHeaderType==1">
             <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" 
             [userLoggedIn]="userLoggedIn">
             
             </calsoft-mobile-header>
         </div>
     
         <div *ngIf="mobileHeaderType==0">
             <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" 
             [userLoggedIn]="userLoggedIn">
             
             </calsoft-mobile-header-new>
         </div>
  
     </div>
   
   
     <!-- <calsoft-mobile-header-fixed [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
   
     </calsoft-mobile-header-fixed> -->
   <!-- Mobile View  end -->
   
   